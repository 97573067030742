<template>
  <div>
    <v-card
      :loading="loading"
      flat
      style="
        border-radius: 20px;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="2">
            <v-card
              flat
              style="background: var(--v-greyRaised-base) !important"
            >
              <v-list>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-content>
                    <!-- <v-list-item-title>Unprocessed Files</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3" >
                    <v-icon>pending_actions</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Unprocessed Files
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="openUnprocessed(false)" style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.unprocessedFiles  ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>account_balance</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Not Ready to Invoice
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="openViewNotReady(false)" style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.notReadyToInvoice  ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>checklist</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Documentation</v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals:
                      <v-chip @click="openDocumentationDialog(false, 'OVERDUE', data.totals?.OVERDUE ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="error" small>{{ data.totals?.OVERDUE  ?? 0 }}</v-chip>
                      <v-chip @click="openDocumentationDialog(false, 'DANGER', data.totals?.DANGER ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="warning" small>{{ data.totals?.DANGER  ?? 0 }}</v-chip>
                      <v-chip @click="openDocumentationDialog(false, 'GOOD', data.totals?.GOOD ?? 0)" class="pa-1 mx-1" style="height: 20px !important;" color="success" small>{{ data.totals?.GOOD  ?? 0 }}</v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0" style="height: 50px">
                  <v-list-item-icon class="mr-3 mb-2 mt-3">
                    <v-icon>assignment</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Open Files
                    </v-list-item-title>
                    <v-list-item-subtitle style="height: 20px !important">
                      Totals: <v-chip @click="viewOpenFilesDialog(false)"  style="height: 20px !important;" small class="pa-1 mx-1">{{ data.totals?.openFiles ?? 0 }}</v-chip>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="10">
            <v-row align="center" justify="space-between">
              <v-col cols="1" class="text-center my-0">
                <v-btn
                  icon
                  v-if="!loading && activeWeeks.length > 0"
                  @click="prevPage()"
                  color="primary"
                  :disabled="page == 0"
                >
                  <v-icon>arrow_back</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-for="(week, index) in data.weekResult"
                :key="index"
                cols="2" class="my-0"
              >

              <div>
                <v-card
                  elevation="10"
                  style="
                    background: var(--v-component-lighten1) !important;
                    border-radius: 20px;
                    height: 280px;
                  "
                  v-if="!loading "
                >
                  <v-list>
                    <v-list-item style="height: 50px;">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          {{ week.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openUnprocessed(week, week.unprocessedFiles)"
                            color="secondary"
                            v-if="week.unprocessedFiles > 0"
                            >{{ week.unprocessedFiles }}</v-chip
                          ><span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openViewNotReady(week)"
                            color="secondary"
                            v-if="week.notReadyToInvoice > 0"
                            >{{ week.notReadyToInvoice }}</v-chip
                          > <span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip
                            @click="openDocumentationDialog(week, 'OVERDUE', week.OVERDUE)"
                            v-if="week.OVERDUE"
                            color="error"
                            class="mx-1"
                            >{{ week.OVERDUE }}</v-chip
                          >
                          <v-chip
                            @click="openDocumentationDialog(week, 'DANGER', week.DANGER)"
                            v-if="week.DANGER"
                            color="warning"
                            class="mx-1"
                            >{{ week.DANGER }}</v-chip
                          >
                          <v-chip
                            @click="openDocumentationDialog(week, 'GOOD', week.GOOD)"
                            v-if="week.GOOD"
                            color="success"
                            class="mx-1"
                            >{{ week.GOOD }}</v-chip
                          >
                          <span v-if="!week.OVERDUE && !week.DANGER && !week.GOOD">-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 50px">
                      <v-list-item-content class="text-center">
                        <v-list-item-title>
                          <v-chip @click="viewOpenFilesDialog(week, week.openFiles)" color="secondary" v-if="week.openFiles > 0">{{
                            week.openFiles
                          }}</v-chip><span v-else>-</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
      

              
               
                <v-card
                  elevation="10"
                  v-else
                  style="
                    background: var(--v-component-lighten1) !important;
                    border-radius: 20px;
                    height: 280px
                  "
                >
                  <v-skeleton-loader tile
                    type="list-item, list-item, list-item, list-item,list-item,list-item"
                  ></v-skeleton-loader>
                </v-card>
              </div>

              </v-col>

              <v-col cols="1" class="text-center my-0">
                <v-btn
                  icon
                  v-if="!loading && activeWeeks.length > 0"
                  @click="nextPage()"
                  color="primary"
                  :disabled="
                    page == Math.ceil(activeWeeks.length / pageLimit) - 1
                  "
                >
                  <v-icon>arrow_forward</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="openDocsDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="overflow: hidden; contain: content;"
    >
    <v-card style="height: 100vh;  contain: content">
      <DashboardDocsModal
      :opened="openDocsDialog"
      :week="chosenWeek"
      :kpiCategory="chosenKpiCategory"
      :filter="filterForDialog"
      @close="closeDocsDialog()"
      />
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="viewUnprocessed"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <v-card>
        <ReadyFiles :week="chosenWeek" :opened="viewUnprocessed" :filter="filterForDialog" :isDialog="true" @close="closeViewUnprocessed"/>
    </v-card>
    </v-dialog>
    <v-dialog
        v-model="viewNotReady"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <NotReadyToInvoice :week="chosenWeek" :opened="viewNotReady" :filter="filterForDialog" :isDialog="true" @close="closeViewNotReady"/>
    </v-card>
    </v-dialog>
    <v-dialog
        v-model="viewOpenFiles"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card>
        <OpenFiles :opened="viewOpenFiles" :filter="filterForDialog" :week="chosenWeek" :isDialog="true" @close="closeOpenFiles"/>
    </v-card>
  </v-dialog>
    <v-dialog v-model="errorDialog" width="40vw">
      <v-card height="auto">
        <v-toolbar flat dense class="d-flex justify-center align-center">
          <v-toolbar-title>Request too large.</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="d-flex justify-center align-center text-center pb-0">
          This request is too large to process and will likely timeout. Please apply a filter to reduce the number of results to under 500. 
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn @click="errorDialog = false" color="redPop">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DashboardDocsModal from "./DashboardDocsModal.vue";
import ReadyFiles from "./ReadyFiles.vue";
import NotReadyToInvoice from "./NotReadyToInvoice.vue";
import OpenFiles from "./OpenFiles.vue";
export default {
  components: {
    DashboardDocsModal,
    ReadyFiles,
    NotReadyToInvoice,
    OpenFiles
  },
  props: ["activeWeeks", "filter", "filterKey", 'loadingKey'],
  data: () => ({
    data: [],
    dataQuery: undefined,
    dataTimeout: undefined,
    errorDialog: false,
    viewNotReady: false,
    viewOpenFiles: false,
    loading: false,
    viewUnprocessed: false,
    shippers: [],
    page: 0,
    notReadyKey: 22,
    pageLimit: 5,
    chosenWeek: null,
    chosenKpiCategory:  null,
    openDocsDialog: false,
    filterForDialog: {},
  }),
  mounted() {
    // this.getFilteredData();
  },
  watch: {
    filterKey() {
      this.getFilteredData();
    },
    loadingKey() {
      this.loading = true;
    }
  },
  computed: {
    availableData() {
      return this.data.weekResult.filter(
        (x) =>
          x.notReadyToInvoice > 0 || x.unprocessedFiles > 0 || x.openFiles > 0
      );
    },
    availableWeeks() {
      let data = [...this.activeWeeks];
      let sliceResult = data.slice(
        this.page * this.pageLimit,
        this.page * this.pageLimit + this.pageLimit
      );
      console.log("sliceResult", this.page, this.pageLimit, this.activeWeeks, sliceResult)
      return sliceResult
    },
    sliceWeeks() {
      let data = [...this.availableData];
      return data.slice(
        this.page * this.pageLimit,
        this.page * this.pageLimit + this.pageLimit
      );
    },
  },
  created() {
    
  },
  methods: {
    closeViewNotReady() {
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewNotReady = false;
    },
    openViewNotReady(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewNotReady = true;
    },
    openUnprocessed(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewUnprocessed = true;
    },
    closeViewUnprocessed() {
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewUnprocessed = false;
    },
    viewOpenFilesDialog(week) {
      this.chosenWeek = week ? week.text : null;
      this.filterForDialog = {...this.filter}
      this.viewOpenFiles = true;
    },
    closeOpenFiles(){
      this.chosenWeek = null;
      this.filterForDialog = {}
      this.viewOpenFiles = false;
    },

    openDocumentationDialog(week, kpiCategory, total) {
      if(total > 500 ) {
        if(!this.filter.assignedUserId && !this.filter.customerId && !this.filter.vesselVoyage && (!this.filter.teams || this.filter.teams.length == 0)) {
          this.errorDialog = true;
          return
        }
      }
      this.chosenWeek = week ? week : null;
      this.chosenKpiCategory = kpiCategory;
      this.filterForDialog = {...this.filter}
      this.openDocsDialog = true;
    },
    closeDocsDialog() {
      this.chosenWeek = null;
      this.chosenKpiCategory = null;
      this.filterForDialog = {}
      this.openDocsDialog = false;
    },
    async getFilteredData() {
      if (this.loading) {
        clearTimeout(this.dataTimeout);
      }
      if (this.loading && this.dataQuery) {
        this.dataQuery.abort();
      }
      this.loading = true;
      this.dataTimeout = setTimeout(async () => {
        this.dataQuery = new AbortController();
        let filterFinal = null
        if(this.filter && this.filter.vesselVoyage && this.filter.vesselVoyage?.voyage) {
            filterFinal = {
                ...this.filter,
                vesselVoyage: {
                    voyage: this.filter.vesselVoyage.voyage,
                    id: this.filter.vesselVoyage.id,
                }
            }
        } else {
            filterFinal = {...this.filter}
        }
        const signal = this.dataQuery.signal;
        let filter = {
          ...filterFinal,
          weeks: this.availableWeeks.map((x) => x.text),
          getShippers: this.shippers.length > 1 ? false : true,
        };
        this.data = await this.$API.getDocumentDashboardData({
          params: filter,
          signal,
        });
        let weeklyTotal = this.data.weekResult
        if(this.data.shippers && this.data.shippers.length > 0) {
          this.shippers = JSON.parse(JSON.stringify(this.data.shippers));
          this.$emit("updateShippers", this.shippers);
        }
        this.totalsAll = this.data.totals;
        let unprocessedFileTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.unprocessedFiles,
          0
        );
        let notReadyToInvoiceTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.notReadyToInvoice,
          0
        );
        let documentationTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.documentTotal,
          0
        );
        let openFilesTotal = weeklyTotal.reduce(
          (acc, week) => acc + week.openFiles,
          0
        );
        this.$emit("updateTotals", {
          unprocessedFileTotal,
          notReadyToInvoiceTotal,
          documentationTotal: documentationTotal,
          openFilesTotal,
        });
        this.loading = false;
      }, 500);
    },
    nextPage() {
      this.page++;
      this.getFilteredData();
    },
    prevPage() {
      this.page--;
      this.getFilteredData();
    },
  },
};
</script>